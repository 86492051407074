import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc HasDownloader]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc GetDownloadedByteCount]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc GetTotalRemoteByteCount]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlPageDidChangeNotification</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlPreviousPageNumberUserInfoKey</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlCurrentPageNumberUserInfoKey</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/BookmarkUtils.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTBookmarkInfoKey</div>
<div class="difference"><span class="status added">Added</span> PTBookmarkInfoKeyPageNumber</div>
<div class="difference"><span class="status added">Added</span> PTBookmarkInfoKeySDFObjNumber</div>
<div class="difference"><span class="status added">Added</span> PTBookmarkInfoKeyName</div>
<div class="difference"><span class="status added">Added</span> PTBookmarkInfoKeyUniqueId</div>
<div class="difference"><span class="status added">Added</span> +[BookmarkUtils deleteBookmarkDataForDocument:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/BookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[BookmarkViewController initWithToolManager:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[BookmarkViewControllerDelegate bookmarkViewController:selectedBookmark:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)bookmarkViewController:(nonnull BookmarkViewController *)bookmarkViewController selectedBookmark:(nonnull NSDictionary *)aBookmark</td></tr>
<tr><th>To</th><td class="declaration">- (void)bookmarkViewController:(nonnull BookmarkViewController *)bookmarkViewController selectedBookmark:(nonnull PTUserBookmark *)bookmark</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> BookmarkViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>UITextViewDelegate</td></tr>
<tr><th>To</th><td><em>none</em></td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> BookmarkViewController.bookmarks</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nonnull) NSMutableArray *bookmarks</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nonnull) NSMutableArray&lt;PTUserBookmark *&gt; *bookmarks</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.searchButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.shareButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.settingsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.bookmarksButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.annotationButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.navigationListsViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController showNavigationLists]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.annotationToolbarHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.findTextToolbarHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pageIndicatorHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController setPageIndicatorHidden:animated:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController.thumbnailSliderHidden</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, assign) BOOL thumbnailSliderHidden</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, assign, getter=isThumbnailSliderHidden) BOOL thumbnailSliderHidden</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTModel.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTModel localPropertyKeysForClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTModel localDictionaryValueForClass:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.selectedViewController</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.selectedIndex</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>UIPopoverPresentationControllerDelegate</td></tr>
<tr><th>To</th><td><em>none</em></td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPageIndicatorViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPageIndicatorViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTPageIndicatorViewController initWithPDFViewCtrl:]</div>
<div class="difference"><span class="status added">Added</span> PTPageIndicatorViewController.backgroundView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTUserBookmark.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTUserBookmark</div>
<div class="difference"><span class="status added">Added</span> -[PTUserBookmark initWithPDFBookmark:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUserBookmark initWithTitle:pageNumber:pageObjNum:]</div>
<div class="difference"><span class="status added">Added</span> PTUserBookmark.bookmark</div>
<div class="difference"><span class="status added">Added</span> PTUserBookmark.title</div>
<div class="difference"><span class="status added">Added</span> PTUserBookmark.pageNumber</div>
<div class="difference"><span class="status added">Added</span> PTUserBookmark.pageObjNum</div>
<div class="difference"><span class="status added">Added</span> PTUserBookmark.edited</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailSliderViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ThumbnailSliderViewController.leadingView</div>
<div class="difference"><span class="status added">Added</span> ThumbnailSliderViewController.trailingView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[ThumbnailsViewController initWithToolManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailViewCell.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ThumbnailViewCell.editing</div>
<div class="difference"><span class="status added">Added</span> -[ThumbnailViewCell setEditing:animated:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailViewLayout.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ThumbnailViewLayoutDefaultSpacing</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolsMacros.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_DEPRECATED_MSG</div>
<div class="difference"><span class="status added">Added</span> #def PT_DEPRECATED</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}